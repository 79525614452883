
























import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import damageLocatorImage from "./damageLocatorImage";
import { ConfigModule } from "@/store/modules/config";
import { Svg, SVG, Path } from "@svgdotjs/svg.js";

@Component({
  components: {}
})
export default class DamageLocator extends Vue {
  @Prop({ default: [] })
  damageLocations!: string[];

  @Ref("locatorDiv")
  readonly locatorDiv!: HTMLDivElement;

  @Prop()
  public clickedColor!: string;

  private calculatedHeight = 50;

  get height() {
    return this.calculatedHeight;
  }

  created() {
    window.addEventListener("resize", this.setDivHeightByWidth);
  }

  setDivHeightByWidth() {
    if (!this.locatorDiv) return;

    const ratio = 4 / 3;
    const width = this.locatorDiv.offsetWidth;
    const height = width * ratio;

    this.calculatedHeight = height;
  }

  mapAttr = {
    viewBoxWidth: 524,
    viewBoxHeight: 680,
    imageWidth: 524,
    imageHeight: 680
  };

  svgContainer = null;

  mounted() {
    this.generateVenueMap();
    this.setDivHeightByWidth();
  }

  generateVenueMap() {
    const path = damageLocatorImage.g.path;

    const svgContainer = SVG()
      .addTo("#damageLocatorImage")
      // .size("80%", "80%")
      .viewbox(0, 0, this.mapAttr.viewBoxWidth, this.mapAttr.viewBoxHeight);

    path.forEach(pathObj => {
      this.generatePath(svgContainer, pathObj);
    });
  }

  generatePath(svgCont: Svg, pathObj: PathObject) {
    const title = pathObj["-title"];
    let fillColor = this.clickedColor;
    if ("#888888" == fillColor) {
      fillColor = ConfigModule.color.primary;
    }

    let clicked = false;
    let fill = pathObj["-fill"];

    if (this.damageLocations.includes(title)) {
      clicked = true;
      fill = fillColor;
    }

    const attrs = {
      fill: fill,
      unselectedColor: pathObj["-fill"],
      clicked: clicked,
      stroke: "white",
      "stroke-width": 1,
      title: title,
      "map-id": pathObj["-id"]
    };
    const element: Path = svgCont.path(pathObj["-d"]).attr(attrs);

    let clickedElement = {};
    element.click(function(this: Path) {
      const svgPathClickObject: SvgPathClickObject = {
        mapId: this.node.attributes["map-id"].value,
        title: this.node.attributes["title"].value
      };

      if (this.node.attributes["clicked"].value === "true") {
        this.node.attributes["clicked"].value = "false";
        this.node.attributes["fill"].value = this.node.attributes["unselectedColor"].value;

        clickedElement = svgPathClickObject;
      } else {
        this.node.attributes["clicked"].value = "true";
        this.node.attributes["fill"].value = fillColor;

        clickedElement = svgPathClickObject;
      }
    });

    element.on("click", () => {
      this.$emit("svgClicked", clickedElement);
    });
  }
}

export interface SvgPathClickObject {
  mapId: string;
  title: string;
}

interface PathObject {
  "-id": string;
  "-title": string;
  "-class": string;
  "-fill": string;
  "-d": string;
  "-self-closing": string;
}
