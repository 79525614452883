

















import { Component, Vue } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import DamageLocator, { SvgPathClickObject } from "@/components/damagelocator/DamageLocator.vue";
import { ReportModule } from "@/store/modules/report.store";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";

@Component({
  components: {
    LayoutReportStep,
    DamageLocator
  }
})
export default class ReportDamageLocation extends Vue implements IReportStep {
  header: IReportHeaderData = {
    title: "Wo ist der Schaden?",
    description:
      "Bitte wählen Sie den Schadensbereich durch anklicken des Bereichs. Keine Sorge, eine grobe Auswahl ist ausreichend."
  };

  damageLocation: string[] = ReportModule.inCreationReport.damageLocation;

  get isDone() {
    return this.damageLocation.length >= 1;
  }

  svgClicked(e: any) {
    if (!e) return;

    const clickedObject: SvgPathClickObject = {
      mapId: e.mapId,
      title: e.title
    };

    if (this.damageLocation && this.damageLocation.includes(clickedObject.title)) {
      ReportModule.popDamageLocation(clickedObject.title);
    } else {
      ReportModule.pushDamageLocation(clickedObject.title);
    }
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
